import React from 'react';
import kpiVideo from '../videos/Kpi Vedio.mp4';

const KpiVideoModal = ({ onClose }) => {
    return (
        <div>
            {/* Modal */}
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
                <div className="relative w-full max-w-3xl p-4 mx-4 bg-white rounded-lg shadow-lg">
                    {/* Close button */}
                    <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
                        &times;
                    </button>

                    {/* Responsive Video */}
                    <div className="aspect-w-16 aspect-h-9 w-full">
                        <video controls className="w-full h-full rounded">
                            <source src={kpiVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KpiVideoModal;
